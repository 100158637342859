// eslint-disable-next-line import/prefer-default-export
export const actionIdToActionType = {
    1: 'CRIAÇÃO DE OPT-IN',
    2: 'EDIÇÃO DE OPT-IN',
    3: 'BUSCA DE OPT-IN',
    4: 'OPT-IN REJEITADO',
    5: 'OPT-OUT',
    6: 'CRIAR AVERBAÇÃO',
    7: 'EDIÇÃO DE AVERBAÇÃO',
    8: 'BUSCA DE AVERBAÇÃO',
    9: 'CONSULTA DE AGENDA',
    10: 'CRIAR RASCUNHO DA AVERBAÇÃO',
    11: 'EDITAR RASCUNHO DA AVERBAÇÃO',
    12: 'APAGAR RASCUNHO DA AVERBAÇÃO',
    13: 'CONSULTA DE AGENDA',
    14: 'RELATIONSHIP_CHARGE',
    15: 'MANUTENÇÃO DE AVERBAÇÃO',
};
