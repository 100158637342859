import OsirisService from './BaseOsirisService';

class RateService extends OsirisService {
    async index() {
        try {
            return await this.api.get('/rate/standard');
        } catch ({ response }) {
            return response;
        }
    }

    async update(data) {
        try {
            return await this.api.put('/rate/standard', { data });
        } catch ({ response }) {
            return response;
        }
    }
}

export default new RateService();
